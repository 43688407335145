@font-face {
  font-family: 'Gotham-small';
  font-weight: 400;
  font-size: 16px;
  src: url('./assets/fonts/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham-regular';
  font-weight: 400;
  font-size: 14px;
  src: url('./assets/fonts/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  src: url('./assets/fonts/GothamBook-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham-xs';
  font-weight: 400;
  font-size: 12px;
  src: url('./assets/fonts/GothamBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham-medium';
  font-weight: 400;
  font-size: 14px;
  src: url('./assets/fonts/GothamBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham-heading';
  font-weight: 400;
  font-size: 38px;
  src: url('./assets/fonts/GothamBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham-bold';
  font-weight: 700;
  src: url('./assets/fonts/GothamBold.ttf') format('truetype');
}

label {
  // fonts might not be configured correctly,
  // we've copied them from the old project,
  // no font-weight 500 is present,
  // so we'll use 700 for now
  font-weight: 700;
  font-size: 16px;
}

// .ant-layout-header {
//   height: initial;
//   line-height: initial;
// }

.ant-menu-item-selected {
  font-weight: bold;
}

.ant-notification-notice-message {
  padding-inline-end: 28px;
}
.ant-steps-item-title {
  white-space: nowrap;
}

[data-bp-xs='true'] .ant-steps-item-title {
  white-space: normal;
}

.ni-steps.ant-steps.ant-steps-horizontal {
  &.ant-steps-label-vertical.ant-steps-small .ant-steps-item-tail::after {
    height: 5px;
  }
}

.ant-notification-notice {
  padding: 14px !important;
  .ant-notification-notice-message {
    font-size: 14px !important;
    font-weight: bold !important;
    margin-bottom: 0 !important;
  }
  .ant-notification-notice-close {
    top: 8px !important;
    right: 8px !important;
  }

  [dir='rtl'] {
    .ant-notification-notice-close {
      right: unset !important;
      left: 8px !important;
    }
  }
}
